import React, { useState } from 'react';
import { auth } from '../utils/FirebaseConfig'; // Import the Firebase auth
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // For navigation
import { checkValidData } from '../utils/ValidateForm';

const Auth = ({ onAuthSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(true); // Toggle between Login/Signup
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use React Router's useNavigate for redirecting

  // Handle signup or login based on the form type
  const handleAuth = async (e) => {
    e.preventDefault();
    let message = checkValidData(email, password)
    if (message) {
      setError(message);
      return;
    }

    try {
      if (isSignUp) {
        // Signup with Firebase Auth
        createUserWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            // alert('Signup successful');
            onAuthSuccess(userCredential.user); // Pass the user data back to parent
            navigate('/youtube-link'); // Navigate to YouTube link page
          })
          .catch(error =>{
            setError('Email already registered')
          })

      }
      if (!isSignUp) {
        // Login with Firebase Auth
        signInWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            // alert('Login successful');
            onAuthSuccess(userCredential.user); // Pass the user data back to parent
            navigate('/youtube-link'); // Navigate to YouTube link page
          })
          .catch(error =>{
            setError('Invalid Credentials')
          })

      }
    } catch (error) {
      // alert('Error: ' + error.message);
      setError(error.message);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // alert('Google Sign-In successful');
      onAuthSuccess(user); // Pass the user data back to parent
      navigate('/youtube-link'); // Navigate to YouTube link page
    } catch (error) {
      // alert('Error: ' + error.message);
      // setError(error.message);
      console.log('Error: ' + error.message);
    }
  };

  const handleToggleLogin = () => {
    setError(null)
    setIsSignUp(!isSignUp);
  }

  return (
    <div className="flex flex-col items-center justify-center h-full bg-gradient-to-r from-blue-400 to-purple-400">
      <h1 className="text-4xl font-bold my-4">Welcome to YouNedia</h1>
      <p className="underline text-lg mb-6">PROMOTE YOUR VIDEO TO MILLIONS</p>
      <p className="text-lg mb-6">Get more YouTube views on your videos. Sign up now and get seen.</p>

      <form onSubmit={handleAuth} className=" bg-gray-800 bg-opacity-70 w-[27vw] text-white p-6 px-10 rounded shadow-md">
        <h1 className="text-2xl font-bold mb-4">{isSignUp ? 'Sign Up' : 'Login'}</h1>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block border p-2 w-full mb-3 text-white rounded opacity-70 bg-[#1A1F27] outline-none border-none"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="block border p-2 w-full mb-1 text-white rounded opacity-70 bg-[#1A1F27]  outline-none border-none"
        />
        {
          error ? <p className='text-red-600  mb-2'>{error}</p> : <p className='mb-3'></p>
        }
        <button type="submit" className="bg-blue-500 hover:bg-blue-600  text-white font-semibold py-2 px-4 rounded w-full">
          {isSignUp ? 'Sign Up' : 'Login'}
        </button>
        <p className='w-full text-center mt-4 font-semibold text-lg'>OR</p>
        <button
          type="button"
          onClick={handleGoogleSignIn}
          className="hover:opacity-80  text-white my-2 rounded w-full flex items-center justify-center"
        >
          <img src={require("./google.png")} className='bg-white rounded' width="195" alt="Google" />
        </button>
        <div className="mt-4 ml-5 text-white cursor-pointer hover:opacity-90 hover:underline" onClick={handleToggleLogin}>
          {isSignUp ? <p>Already a member? <span className='font-bold'>Login</span></p> : <p>New to YouNedia? <span className='font-bold'>SignUp</span></p>}
        </div>

      </form>
      <p className="bold-100 my-6 text-center text-black-700 text-sm">Here’s how it works: Sign up, paste your YouTube link, set your budget, and we’ll handle the rest. Your video will be promoted to your target audience, helping you gain more visibility and engagement.</p>

    </div>
  );
};
export default Auth;
