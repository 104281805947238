import { auth } from '../utils/FirebaseConfig'; // Import your Firebase config
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // For navigation
import logo from './YouNedia.png';
const Header = ({ user, onSignOut }) => {
  const navigate = useNavigate(); // Use React Router's useNavigate for redirecting

  const handleSignOut = async () => {
    try {
      await signOut(auth); // Sign out the user from Firebase
      onSignOut(); // Set user to null in App.js
      navigate('/'); // Redirect to the login page
    } catch (error) {
      console.error('Sign Out Error', error);
    }
  };

  return (
    <header className="p-4 bg-gray-800 text-white flex justify-between items-center">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-10" />
        <h1 className="ml-4 text-2xl font-bold">YouTube Promotion</h1>
      </div>
      <div className="flex items-center">
        {user ? (
          <div className="flex items-center space-x-4">
            {user.photoURL && (
              <img
                src={user.photoURL}
                alt={user.displayName || 'Profile'}
                className="w-8 h-8 rounded-full"
              />
            )}
            <span>{user.displayName || 'User'}</span>
            <button
              onClick={handleSignOut}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Sign Out
            </button>
          </div>
        ) : (
          <p></p>
        )}
      </div>
    </header>
  );
};
export default Header;
